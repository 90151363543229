
//    const env_name = "local"
const env_name = "live"
var backurl = ""
if (env_name === "local") {
    backurl = "http://localhost:9374"
}
else {


    // backurl = "https://backend-genesis.maticz.in" // demo
    backurl = "https://api.genesis-pt.org" // live
    // backurl = "https://genesisprotocalapi.maticz.in" //sales
}

export default backurl;